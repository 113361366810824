define([
  'underscore',
  'jquery',
  'modules/upx/components/upx',

  'modules/shop.cash-register-retail/components/feature',
  'modules/common/components/currency',

  'upx.modules/ShopModule/models/LoyaltyProgram',
], (
  _, $, Upx,
  Feature, Currency,
  LoyaltyProgramModel,
) => {
  const Model = LoyaltyProgramModel.extend({

    loaded: false,

    loadIfActive(force) {
      let def = new $.Deferred();
      $.when(Feature.load()).then(() => {
        if (Feature.isLoyaltyFeatureEnabled()) {
          def = this.load(force);
        } else {
          this.loaded = true;
          def.resolve();
        }
      }, def.reject);

      return def;
    },

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('ShopModule', 'listLoyaltyPrograms',
          {
            start: 0,
            limit: 1,
          })).then((response) => {
          self.unload();
          if (response.data.length) {
            self.set(response.data[0]);
          }
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load LoyaltyProgramModel!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    fetchLoyaltyCustomer(relation_data_id) {
      const def = $.Deferred();
      Upx.call(
        'ShopModule', 'listLoyaltyCustomers',
        {
          start: 0,
          limit: 1,
          filters: [
            {
              name: 'loyalty_program_id__=',
              val: this.get('id'),
            },
            {
              name: 'relation_data_id__=',
              val: relation_data_id,
            },
          ],
        },
      ).then(
        (resp) => {
          const { data } = resp;
          def.resolve(_.first(data));
        },
        def.reject,
      );
      return def.promise();
    },

    isActive() {
      return !!this.get('active');
    },

    get(name) {
      if (!this.loaded) {
        throw new Error('LoyaltyProgramModel is queried before loading');
      }
      return LoyaltyProgramModel.prototype.get.call(this, name);
    },

    unload() {
      this.clear();
    },

    getGainedPointsValueOfFloat(amount) {
      let pointsValue = null;
      if (this.get('cents_per_point_on_invoice')) {
        pointsValue = 0.00;
        const centsPerPoint = this.get('cents_per_point_on_invoice') / 100; // Get value in cents format

        if (amount <= 0) {
          pointsValue = Currency.Math.add(
            Currency.toCurrency(pointsValue),
            Currency.toCurrency(0.00),
          );
        } else {
          pointsValue = Currency.Math.div(
            Currency.toCurrency(amount),
            Currency.toCurrency(centsPerPoint), // Value of cents
          );
        }
      }

      return Math.floor(pointsValue || 0);
    },

    getSpentPointsValueOfFloat(amount) {
      let pointsValue = null;
      if (this.get('points_per_cent_with_payment')) {
        pointsValue = 0.00;
        const pointsPerCent = this.get('points_per_cent_with_payment');

        if (amount <= 0) {
          pointsValue = Currency.Math.add(
            Currency.toCurrency(pointsValue),
            Currency.toCurrency(0.00),
          );
        } else {
          const amountInCents = Currency.Math.div(
            Currency.toCurrency(amount),
            Currency.toCurrency(0.01), // Value of cents
          );
          pointsValue = Currency.Math.mul(
            amountInCents,
            Currency.toCurrency(pointsPerCent),
          );
        }
      }

      return Currency.Math.integralPart(pointsValue);
    },

    getSpentCurrencyValueOfPoints(points, currency = 'EUR') {
      let exchangeValue = null;
      if (this.get('points_per_cent_with_payment')) {
        exchangeValue = 0.00;
        const pointsPerCent = this.get('points_per_cent_with_payment');

        if (points <= 0 || points < pointsPerCent) {
          exchangeValue = Currency.Math.add(
            Currency.toCurrency(exchangeValue),
            Currency.toCurrency(0.00),
          );
        } else {
          exchangeValue = Currency.Math.div(
            Currency.toCurrency(points),
            Currency.toCurrency(pointsPerCent),
          );

          exchangeValue = Currency.Math.mul(
            exchangeValue,
            Currency.toCurrency(0.01),
          );
        }
      }

      return Currency.format(currency, exchangeValue);
    },
  });

  return new Model();
});
