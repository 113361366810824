define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/collections/DeepModelCollection',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/acls/settings',
  'modules/shop.cash-register-retail/components/environment',
], function (
  $, _, Backbone, DeepModelCollection,
  CashRegisterApi, Locale, Toaster, SettingsAcl, Environment,
) {
  return Backbone.DeepModel.extend({

    PRINTER_TYPE_NONE: 'type-none',
    PRINTER_TYPE_CUPS: 'type-cups',
    PRINTER_TYPE_ESCPOS: 'type-escpos',
    PRINTER_TYPE_WEB: 'type-web',
    PRINTER_TYPE_ESCPOS_NETWORK: 'type-escpos-network',
    PRINTER_TYPE_ZPL: 'type-zpl',
    PRINTER_TYPE_LP: 'type-lp',

    defaults: {
      id: null,
      type: this.PRINTER_TYPE_NONE,
    },

    initialize() {
      this.fetch();
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    destroy() {
      Backbone.DeepModel.prototype.destroy.call(this);
      this.set(this.defaults);
    },

    /**
         * Methods
         */

    resetPrinter() {
      this.destroy();
      this.set({
        id: 0,
        type: this.PRINTER_TYPE_NONE,
      });
      this.save();
    },

    setPrinter(printerId, type) {
      var type = type || this.getWantedType();
      this.destroy();
      this.set({
        id: printerId,
        type,
      });
      this.save();
    },

    isWantedType() {
      return this.getWantedType() === this.get('type');
    },

    canOpenDrawer() {
      const type = this.get('type');
      return type === this.PRINTER_TYPE_ESCPOS || type === this.PRINTER_TYPE_CUPS || this.PRINTER_TYPE_ESCPOS_NETWORK;
    },

    openDrawer() {
      const def = new $.Deferred();
      console.log('Tried to open de drawer');

      if (this.canOpenDrawer()) {
        CashRegisterApi.logAction('OPEN_DRAWER');
        this.openDrawerFn()
          .then(def.resolve, def.reject);
      } else {
        console.error('[Open Drawer] The selected printer is not a CUPS or as ESCPOS printer');
        def.reject();
      }

      return def;
    },

    fetchPrinters(options) {
      options = options || {
        neverReject: true,
      };

      const def = new $.Deferred();

      this.fetchPrintersFn()
        .then((response) => {
          // To make sure we dont send back too much.
          def.resolve({
            printers: response.printers,
          });
        }, (response) => {
          // Resolve with empty collection.
          if (options.neverReject) {
            def.resolve({
              printers: new DeepModelCollection(),
            });
          }
          // Else just reject
          else {
            def.reject(response);
          }
        });

      return def;
    },

    printHtml(htmlText, options) {
      options = options || {};
      const def = new $.Deferred();
      const self = this;

      def.fail((response) => {
        if ('error_title' in response) {
          Toaster.error(response.error, response.error_title);
        } else {
          Toaster.error(response.error);
        }
      });

      if (this.printerSelectedCheck(def)) {
        CashRegisterApi.logAction('PRINT_START', {
          model: this.toJSON(),
          options,
        });

        this.printHtmlFn(htmlText, options)
          .then(def.resolve, def.reject);

        def.then(() => {
          CashRegisterApi.logAction('PRINT_SUCCESS', {
            model: self.toJSON(),
            options,
          });
        }, (response) => {
          CashRegisterApi.logAction('PRINT_ERROR', {
            model: self.toJSON(),
            options,
            response,
          });
        });
      }

      return def;
    },

    /**
         * Abstract functions
         */

    getWantedType() {
      console.warn('"fetchPrinters" is not implemented yet');
      return this.PRINTER_TYPE_NONE;
    },

    fetchPrintersFn() {
      const def = new $.Deferred();
      console.warn('"fetchPrintersFn" is not implemented yet');
      return def.resolve({
        printers: new DeepModelCollection(),
      });
    },

    openDrawerFn() {
      const def = new $.Deferred();
      console.warn('"openDrawerFn" is not implemented yet');
      return def.resolve();
    },

    printJsonData() {
      const def = new $.Deferred();
      console.warn('"printJsonData" is not implemented yet');
      return def.resolve();
    },

    printHtmlFn(htmlText, options) {
      const def = new $.Deferred();
      console.warn('"printHtml" is not implemented yet');
      return def.resolve();
    },

    printerSelectedCheck(def) {
      if (!this.has('id') || this.get('id') == '0') {
        const acl = new SettingsAcl();
        let error;

        if (acl.authorized()) {
          error = Locale.translate('select_a_printer_via_the_settings_page');
        } else {
          error = Locale.translate('ask_your_manager_administrator_to_select_a_printer_via_the_settings_page');
        }

        def.reject({
          error,
          error_title: Locale.translate('no_printer_selected'),
        });
        return false;
      }
      return true;
    },

    receiptPreviewIndex: 0,
    showReceiptPreview(printHtml, type) {
      if (Environment.isDevelopment()) {
        type = type || 'receipt';
        // see
        let width = 575; // RECEIPT
        if (type === 'sticker') {
          width = 368;// STICKER
        } else if (type === 'bread') {
          width = 455;// STICKER
        }
        console.warn('Rendering receipt preview, new window popup (make sure it\'s not blocked)', { printHtml, type });
        const receiptPreviewIndex = this.receiptPreviewIndex++; // Required to how multiple popups
        const win = window.open('', `Printout ${type} ${receiptPreviewIndex}`, `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=500`);
        win.document.documentElement.innerHTML = printHtml;
      }
    },

  });
});
