define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/collections/DeepModelCollection',
  '@storekeeper/escpos',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/components/pinReceipt',
], (
  $, _, Backbone, DeepModelCollection, EscPos,
  Locale, DefaultShopConfigurationModel, PinReceiptComponent,
) => Backbone.Model.extend({

  builder: null,

  initialize() {
    this.builder = new EscPos.EscPosBuilder();
  },

  serialize() {
    const data = this.builder.serialize();

    // Clear the current builder
    this.builder = new EscPos.EscPosBuilder();

    return data;
  },

  companyLogo(size = 500) {
    let logoUrl = DefaultShopConfigurationModel.getBlackAndWhiteCompanyLogo();
    if (!logoUrl) {
      // else use the coloured one
      logoUrl = DefaultShopConfigurationModel.getCompanyLogo();
    }

    if (logoUrl) {
      this.builder
        .image(logoUrl, { alignHT: 'center', width: size })
        .linefeed();
    }
  },

  headerText(text) {
    this.builder
      .text(text, { breakOnWord: true, alignHT: 'center' })
      .linefeed();
  },

  footerText(text) {
    this.builder.text(text, { breakOnWord: true, alignHT: 'center' });
  },

  signature(signatureLines = 6) {
    this.builder.lineSpacing(0);
    for (let i = 0; i < signatureLines; i++) {
      this.builder.line({ character: '░' });
    }
    this.builder
      // Reset line spacing
      .lineSpacing()

      // Signature text
      .text(Locale.translate('signature'), { newLine: true, alignHT: 'right' })
      .linefeed();
  },

  receiptSignature(transaction_signature) {
    if (transaction_signature) {
      const {
        data,
        error,
        qr,
      } = transaction_signature || {};
      this.builder.line();

      if (error) {
        this.builder
          .text(error)
          .linefeed();
      }
      if (data) {
        data.forEach(({ label, value }) => {
          this.builder
            .text(label).text(': ').text(value)
            .linefeed();
        });
      }

      if (qr) {
        this.builder
          .qr(qr, {
            alignHT: 'center',
            size: 300,
          })
          .linefeed();
      }
      this.builder.line();
    }
  },

  ccvPinReceipt(pinReceiptLines) {
    for (const line of pinReceiptLines) {
      if (!line.text) {
        // Empty line
        this.builder.linefeed();
      } else {
        const height = line.height === 'double' ? 2 : 1;
        const width = line.width === 'double' ? 2 : 1;

        this.builder.text(line.text, {
          newLine: true,
          height,
          width,
        });
      }
    }
  },

  pinReceipt(pinReceipt) {
    this.builder.line();

    const ccvPinReceipt = PinReceiptComponent.getCCVPinReceipts(pinReceipt);
    if (ccvPinReceipt && ccvPinReceipt.length > 0) {
      // CCV pin receipt
      this.ccvPinReceipt(ccvPinReceipt[0]);
    } else {
      // PayNL pin receipt is just plain text
      this.builder.text(pinReceipt);
    }

    this.builder
      .linefeed()
      .line();
  },
}));
