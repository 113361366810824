define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => {
  const Model = Backbone.Model.extend({

    device: null,
    endpointNumber: null,

    hasDevice() {
      return !!this.device;
    },

    getDevice() {
      if (!this.device) {
        throw new Error('Device not set');
      }
      return this.device;
    },

    setDevice(device) {
      this.device = device;
    },

    unsetDevice() {
      this.device = null;
    },

    setEndpointNumber(endpoint) {
      this.endpointNumber = endpoint;
    },

    getEndpointNumber() {
      return this.endpointNumber;
    },

    async printEscPos(data) {
      await this.getDevice().transferOut(this.getEndpointNumber(), data);
    },

  });

  return new Model();
});
