define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment,
) => AbstractPrintable.extend({

  build(data) {
    this.companyLogo();

    if (data.header_text) {
      this.headerText(data.header_text);
    }

    this.builder
      .text(Locale.translate('packing_slip'), {
        size: 2, newLine: true, alignHT: 'center', bold: true,
      })
      .linefeed();

    this.builder
      .line({ character: '═' })
      .text(`#${data.number}-${data.shipment.id}`, {
        size: 2, bold: true, alignHT: 'center', newLine: true,
      })
      .line({ character: '═' })
      .linefeed();

    // Address
    if (data.shipping_address && data.shipping_address.name) {
      this.shippingAddress(data);
    }

    this.productsTable(data.shipment.shipment_items);
    this.extraInfoTable(data);

    // Barcode
    this.builder.barcode(data.number);

    // Footer text
    if (data.footer_text) {
      this.footerText(data.footer_text);
    }

    this.builder
      .cut();

    return this.serialize();
  },

  shippingAddress(data) {
    this.builder.text(data.shipping_address.name, { newLine: true, bold: true });

    if (data.shipping_address.contact_address) {
      const {
        street, streetnumber, flatnumber, zipcode, city,
      } = data.shipping_address.contact_address;

      const address = `${street || ''} ${streetnumber || ''} ${flatnumber || ''}`;
      const hasFullAddress = data.country && zipcode && city;
      const zipCityCountry = hasFullAddress ? `${zipcode || ''} ${city || ''} ${data.country || ''}` : data.country;

      this.builder
        .text(zipCityCountry, { newLine: true })
        .text(address, { newLine: true });
    }

    this.builder.text(data.phone, { newLine: true });
    this.builder.text(data.email, { newLine: true });

    this.builder.linefeed();
  },

  productsTable(items) {
    const productRows = [];

    items.forEach((item) => {
      productRows.push(
        {
          data: {
            quantity: `${item.quantity}x`,
            product: item.name,
          },
        },
      );

      if (item.sku) {
        productRows.push(
          {
            data: {
              quantity: '',
              product: item.sku,
            },
            size: 0.5,
          },
        );
      }

      if (item.serial_nos && item.serial_nos.length) {
        productRows.push(
          {
            data: {
              quantity: '',
              product: `${Locale.translate('serials')}: ${item.serial_nos.join(', ')}`,
            },
            size: 0.5,
          },
        );
      }
    });

    if (productRows.length > 0) {
      this.builder.table({
        columns: [
          {
            key: 'quantity',
            width: 20,
            header: Locale.translate('qty_dot'),
          },
          {
            key: 'product',
            width: 80,
            alignHTRows: 'right',
            alignHTHeader: 'right',
            header: Locale.translate('products'),
          },
        ],
        rows: productRows,
      }).line();
    }
  },

  extraInfoTable(data) {
    this.builder
      .table({
        columns: [
          {
            key: 'name',
            width: 40,
          },
          {
            key: 'value',
            width: 60,
            alignHTRows: 'right',
          },
        ],
        rows: [
          {
            data: {
              name: Locale.translate('date'),
              value: new Moment(data.date_purchased).format('DD MMMM YYYY HH:mm'),
            },
          },
          {
            data: {
              name: Locale.translate('order_number'),
              value: `#${data.number}`,
            },
          },
          data.shipment_method_name && {
            data: {
              name: Locale.translate('shipping_method'),
              value: data.shipment_method_name,
            },
          },
        ],
      });
  },
}));
