define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/models/ipc/ipc',
], (
  $, _, Backbone,
  IPC,
) => {
  const Model = Backbone.Model.extend({
    logPrefix: '[ReactNativeConfiguration]',
    configuration: {
      version: null,
      build: null,
    },

    async setup() {
      await IPC.ipcSend('react_native_configuration').then((configuration) => {
        this.configuration = configuration;
        console.debug(`${this.logPrefix} Received configuration`, configuration);
      }).catch((e) => {
        console.error(`${this.logPrefix} Could not get configuration from react native`, e);
      });
    },
  });

  return new Model();
});
