define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/components/pinReceipt',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment, PinReceiptComponent,
) => AbstractPrintable.extend({

  build(data) {
    if (data.options && data.options.number) {
      this.builder
        .text(data.options.number.toString(), {
          size: 4, bold: true, alignHT: 'center', newLine: true,
        })
        .linefeed();
    }

    this.companyLogo();

    // Header text
    if (data.header_text) {
      this.headerText(data.header_text);
    }

    // This text will be replaced in the applyReceiptTemplateOptions function
    this.builder.text('{~reprint~}');

    if (data.was_refunded) {
      this.builder.text(Locale.translate('refunded'), {
        size: 3, bold: true, alignHT: 'center', newLine: true,
      });
    }

    // Customer name
    if (data.customer_name) {
      this.builder
        .text(Locale.translate('customer_receipt'), { bold: true, newLine: true })
        .text(data.customer_name, { newLine: true, breakOnWord: true })
        .linefeed();
    }

    // Description
    if (data.description) {
      this.builder
        .text(Locale.translate('description'), { bold: true, newLine: true })
        .text(data.description, { newLine: true, breakOnWord: true })
        .linefeed();
    }

    this.productsTable(data);
    this.priceTable(data);
    this.paymentMethodsTable(data);
    this.partialDeliveryTable(data);
    this.extraInfoTable(data);
    this.loyaltyPointsTable(data);
    this.receiptSignature(data.transaction_signature);

    // Pin receipt
    if (data.pin_receipt) {
      this.pinReceipt(data.pin_receipt);

      // It's a refund that we was was done by pin(because there is a pin receipt)
      if (data.value < 0) {
        this.builder
          .linefeed()
          .text(Locale.translate('the_amount_will_be_deposited_into_your_account_the_next_business_day_dot'), { alignHT: 'center', breakOnWord: true, newLine: true })
          .linefeed();
      }
    }

    // Barcode
    this.builder.barcode(data.number);

    // Footer text
    if (data.footer_text) {
      this.footerText(data.footer_text);
    }

    this.builder
      .cut();

    // CCV pin may have extra pin receipts for the merchant that need to be printed
    this.extraPinReceipts(data);

    return this.serialize();
  },

  productsTable(data) {
    const productRows = [];
    const { was_refunded, order_items, refunded_items } = data;
    let items = [];
    if (was_refunded) {
      items = refunded_items;
    } else {
      items = order_items;
    }
    items.forEach((orderItem) => {
      productRows.push(
        {
          data: {
            quantity: `${orderItem.quantity}x `,
            product: orderItem.name,
            ppu: parseFloat(orderItem.ppu_wt).toFixed(2),
            price: parseFloat(orderItem.price_wt).toFixed(2),
          },
        },
      );

      if (orderItem.description) {
        productRows.push(
          {
            data: {
              quantity: '',
              product: orderItem.description,
              ppu: '',
              price: '',
            },
            size: 0.5,
          },
        );
      }

      if (orderItem.is_discounted && orderItem.before_discount_ppu_wt) {
        productRows.push(
          {
            data: {
              quantity: '> ',
              product: Locale.translate('from_{0}_for_{1}', [
                orderItem.before_discount_ppu_wt ? parseFloat(orderItem.before_discount_ppu_wt).toFixed(2) : 0,
                parseFloat(orderItem.ppu_wt).toFixed(2),
              ]),
              ppu: '',
              price: '',
            },
            size: 0.5,
          },
        );
      }

      if (orderItem.subitems) {
        for (const subItem of orderItem.subitems) {
          productRows.push(
            {
              data: {
                quantity: '',
                product: `+ ${subItem.name}`,
                ppu: parseFloat(subItem.ppu_wt).toFixed(2),
                price: parseFloat(subItem.price_wt).toFixed(2),
              },
            },
          );
        }
      }

      if (orderItem.serial_nos && orderItem.serial_nos.length) {
        productRows.push(
          {
            data: {
              quantity: '',
              product: `${Locale.translate('serials')}: ${orderItem.serial_nos.join(', ')}`,
            },
            size: 0.5,
          },
        );
      }
    });

    if (productRows.length > 0) {
      this.builder.table({
        columns: [
          {
            key: 'quantity',
            width: 15,
            alignHTRows: 'right',
          },
          {
            key: 'product',
            width: 53,
            header: Locale.translate('product'),
          },
          {
            key: 'ppu',
            width: 17,
            header: Locale.translate('ppu_receipt'),
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
          {
            key: 'price',
            width: 17,
            header: Locale.translate('price'),
            alignHTRows: 'right',
            alignHTHeader: 'right',
          },
        ],
        rows: productRows,
      })
        .line();
    }
  },

  priceTable(data) {
    const { was_refunded } = data;

    if (!was_refunded) {
      this.buildPriceTable(data);
    } else {
      this.buildPriceTableForRefund(data);
    }
  },

  buildPriceTable(data) {
    this.builder.table({
      columns: [
        {
          key: 'name',
          width: 60,
        },
        {
          key: 'value',
          width: 40,
          alignHTRows: 'right',
        },
      ],
      rows: [
        {
          data: {
            name: Locale.translate('total_in_{0}', data.currency_iso3),
            value: parseFloat(data.value_wt).toFixed(2),
          },

          bold: true,
        },
        data.has_discount && {
          data: {
            name: Locale.translate('total_discount_in_{0}', data.currency_iso3),
            value: data.total_discount_wt ? parseFloat(data.total_discount_wt).toFixed(2) : 0,
          },
        },
        ...data.vat_totals.map((vatTotal) => ({
          data: {
            name: Locale.translate('vat_{0}', vatTotal.label),
            value: vatTotal.total,
          },
        })),
      ],
    });
  },

  buildPriceTableForRefund(data) {
    this.builder.table({
      columns: [
        {
          key: 'name',
          width: 60,
        },
        {
          key: 'value',
          width: 40,
          alignHTRows: 'right',
        },
      ],
      rows: [
        {
          data: {
            name: Locale.translate('total_in_{0}', data.currency_iso3),
            value: parseFloat(data.refund_value_wt).toFixed(2),
          },
          bold: true,
        },
      ],
    });
  },

  paymentMethodsTable(data) {
    const { was_refunded } = data;
    const paymentMethodRows = data.payment_methods ? data.payment_methods.map((paymentMethod) => ({
      data: {
        name: `${paymentMethod.title}:`,
        value: paymentMethod.ppu_wt,
      },
    })) : [];

    if (!was_refunded && data.change) {
      paymentMethodRows.push({
        data: {
          name: `${Locale.translate('spare_change')}:`,
          value: data.change,
        },
      });
    }

    if (paymentMethodRows.length > 0) {
      this.builder
        .line()
        .table({
          columns: [
            {
              key: 'name',
              width: 55,
              alignHTRows: 'right',
            },
            {
              key: 'value',
              width: 45,
              alignHTRows: 'right',
            },
          ],
          rows: paymentMethodRows,
        });
    }
  },

  partialDeliveryTable(data) {
    if (data.has_not_shipped_order_items && data.not_shipped_order_items) {
      const partialDeliveryRows = [];

      data.not_shipped_order_items.forEach((orderItem) => {
        partialDeliveryRows.push(
          {
            data: {
              quantity: `${orderItem.still_to_be_shipped_quantity}x `,
              product: orderItem.name,
            },
          },
        );

        if (orderItem.description) {
          partialDeliveryRows.push(
            {
              data: {
                quantity: '',
                product: orderItem.description,
              },
              size: 0.5,
            },
          );
        }

        if (orderItem.is_discounted && orderItem.before_discount_ppu_wt) {
          partialDeliveryRows.push(
            {
              data: {
                quantity: '> ',
                product: Locale.translate('from_{0}_for_{1}', [
                  orderItem.before_discount_ppu_wt.toFixed(2),
                  orderItem.ppu_wt.toFixed(2),
                ]),
              },
              size: 0.5,
            },
          );
        }

        if (orderItem.subitems) {
          for (const subItem of orderItem.subitems) {
            partialDeliveryRows.push(
              {
                data: {
                  quantity: '',
                  product: `+ ${subItem.name}`,
                },
              },
            );
          }
        }
      });

      this.builder
        .line()
        .linefeed()
        .text(`${Locale.translate('still_to_be_delivered')}:`, { newLine: true })
        .line()
        .table({
          columns: [
            {
              key: 'quantity',
              width: 15,
              alignHTRows: 'right',
            },
            {
              key: 'product',
              width: 85,
            },
          ],
          rows: partialDeliveryRows,
        });
    }
  },

  extraInfoTable(data) {
    const extraInfoRows = [
      {
        data: {
          name: Locale.translate('sales_person'),
          value: data.cashier_name,
        },
      },
      {
        data: {
          name: Locale.translate('date'),
          value: new Moment(data.date_purchased).format('DD MMMM YYYY HH:mm'),
        },
      },
      {
        data: {
          name: Locale.translate('number_receipt'),
          value: `#${data.number}`,
        },
      },
    ];

    this.builder
      .line()
      .table({
        columns: [
          {
            key: 'name',
            width: 40,
          },
          {
            key: 'value',
            width: 60,
            alignHTRows: 'right',
          },
        ],
        rows: extraInfoRows,
      });
  },

  loyaltyPointsTable(data) {
    if (data.hasLoyaltyPoints) {
      this.builder
        .line()
        .table({
          columns: [
            {
              key: 'name',
              width: 55,
            },
            {
              key: 'value',
              width: 45,
              alignHTRows: 'right',
            },
          ],
          rows: [
            {
              data: {
                name: Locale.translate('old_balance'),
                value: `${data.loyaltyPointsOriginalBalance}`,
              },
              bold: true,
            },
          ],
        });

      this.builder
        .table({
          columns: [
            {
              key: 'name',
              width: 55,
              alignHTRows: 'right',
            },
            {
              key: 'value',
              width: 45,
              alignHTRows: 'right',
            },
          ],
          rows: [
            {
              data: {
                name: Locale.translate('gained_loyalty_points'),
                value: `+ ${data.loyaltyPointsGained}`,
              },
            },
            {
              data: {
                name: Locale.translate('used_loyalty_points'),
                value: `- ${data.loyaltyPointsSpent}`,
              },
            },
          ],
        });

      this.builder
        .table({
          columns: [
            {
              key: 'name',
              width: 55,
            },
            {
              key: 'value',
              width: 45,
              alignHTRows: 'right',
            },
          ],
          rows: [
            {
              data: {
                name: Locale.translate('new_balance'),
                value: `${data.loyaltyPointsNewBalance}`,
              },
            },
          ],
        });
    }
  },

  extraPinReceipts(data) {
    const ccvPinReceipts = PinReceiptComponent.getCCVPinReceipts(data.pin_receipt);

    // As the customer pin receipt is always the first one, we can skip it.
    if (ccvPinReceipts && ccvPinReceipts.length > 1) {
      for (const ccvPinReceipt of ccvPinReceipts.slice(1)) {
        this.builder.linefeed(2);
        this.ccvPinReceipt(ccvPinReceipt);
        this.builder.cut();
      }
    }
  },

}));
