define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
], (
  $, _, Backbone, AbstractPrintable,
  Locale,
) => AbstractPrintable.extend({

  build(data) {
    this.builder
      .text(Locale.translate('current_date_{0}', data.currentDate), { newLine: true })
      .text(`${Locale.translate('currency')}: ${data.currency_iso3}`, { newLine: true })
      .linefeed()
      .table({
        columns: [
          {
            key: 'name',
            width: 50,
          },
          {
            key: 'value',
            width: 50,
            alignHTRows: 'right',
          },
        ],
        rows: [
          {
            data: {
              name: Locale.translate('expected_cash_total'),
              value: data.expectedCash,
            },
          },
          {
            data: {
              name: Locale.translate('new_cash_amount_left'),
              value: data.cashInRegister,
            },
          },
          {
            data: {
              name: Locale.translate('cash_skimmed'),
              value: data.cashSkimmed,
            },
          },
          {
            data: {
              name: Locale.translate('difference'),
              value: data.difference,
            },
            line: true,
          },
          {
            data: {
              name: Locale.translate('remarks'),
              value: data.note || '-',
            },
          },
        ],
      })
      .cut();

    return this.serialize();
  },
}));
