define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment,
) => AbstractPrintable.extend({

  build(data) {
    if (!data.for_kitchen) {
      this.companyLogo();

      // Header text
      if (data.header_text) {
        this.headerText(data.header_text);
      }
    }

    // Tab name
    this.builder
      .text(`${Locale.translate('tab')}: `, { bold: true })
      .text(data.name, { newLine: !data.for_kitchen })
      .linefeed();

    // For kitchen data
    if (data.for_kitchen) {
      this.builder
        .text(`${Locale.translate('added_on')}: `, { bold: true })
        .text(new Moment(data.timeline).format('DD MMMM YYYY HH:mm'))
        .linefeed()
        .text(`${Locale.translate('added_by')}: `, { bold: true })
        .text(data.timeline_author_name, { newLine: true })
        .linefeed();
    }

    if (data.for_kitchen) {
      this.bigOrderItems(data);
    } else {
      this.productsTable(data);
      this.pricingTable(data);
    }

    this.extraInfoTable(data);

    // Footer text
    if (data.footer_text && !data.for_kitchen) {
      this.footerText(data.footer_text);
    }

    this.builder
      .cut();

    return this.serialize();
  },

  bigOrderItems(data) {
    this.builder.line();

    data.order_items.forEach((orderItem, i) => {
      const text = `${orderItem.quantity}x ${orderItem.name}`;

      this.builder.text(text, { size: 2, newLine: true });

      if (orderItem.description) {
        this.builder.text(orderItem.description, { newLine: true, breakOnWord: true });
      }

      if (orderItem.sub_items) {
        for (const subItem of orderItem.sub_items) {
          this.builder.text(`+ ${subItem.name}`, { newLine: true, breakOnWord: true });
        }
      }

      // Don't add line feed at the last one
      if (i !== data.order_items.length - 1) {
        this.builder.linefeed();
      }
    });
  },

  productsTable(data) {
    const productRows = [];

    data.order_items.forEach((orderItem) => {
      productRows.push(
        {
          data: {
            quantity: `${orderItem.quantity}x `,
            product: orderItem.name,
            ppu: orderItem.ppu_wt,
            price: orderItem.price_wt,
          },
        },
      );

      if (orderItem.description) {
        productRows.push(
          {
            data: {
              quantity: '',
              product: orderItem.description,
              ppu: '',
              price: '',
            },
            size: 0.5,
          },
        );
      }

      if (orderItem.sub_items) {
        for (const subItem of orderItem.sub_items) {
          productRows.push(
            {
              data: {
                quantity: '',
                product: `+ ${subItem.name}`,
                ppu: subItem.ppu_wt,
                price: subItem.price_wt,
              },
            },
          );
        }
      }
    });

    if (productRows.length > 0) {
      this.builder
        .table({
          columns: [
            {
              key: 'quantity',
              width: 15,
              alignHTRows: 'right',
            },
            {
              key: 'product',
              width: 53,
              header: Locale.translate('product'),
            },
            {
              key: 'ppu',
              width: 17,
              header: Locale.translate('ppu_receipt'),
              alignHTRows: 'right',
              alignHTHeader: 'right',
            },
            {
              key: 'price',
              width: 17,
              header: Locale.translate('price'),
              alignHTRows: 'right',
              alignHTHeader: 'right',
            },
          ],
          rows: productRows,
        })
        .line();
    }
  },

  pricingTable(data) {
    this.builder.table({
      columns: [
        {
          key: 'name',
          width: 50,
        },
        {
          key: 'value',
          width: 50,
          alignHTRows: 'right',
        },
      ],
      rows: [
        {
          data: {
            name: Locale.translate('total_in_{0}', data.currency_iso3),
            value: data.total_wt,
          },

          bold: true,
        },
      ],
    });
  },

  extraInfoTable(data) {
    this.builder
      .line()
      .table({
        columns: [
          {
            key: 'name',
            width: 40,
          },
          {
            key: 'value',
            width: 60,
            alignHTRows: 'right',
          },
        ],
        rows: [
          {
            data: {
              name: Locale.translate('sales_person'),
              value: data.print_person,
            },
          },
          {
            data: {
              name: Locale.translate('date'),
              value: new Moment(data.print_time).format('DD MMMM YYYY HH:mm'),
            },
          },
        ],
      })
      .linefeed();
  },
}));
