define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/settings/AbstractLpPrinter',
  'dom-to-image',
  '@storekeeper/escpos',
], (
  $, _, Backbone, AbstractLpPrinter, DomToImage, EscPos
) => AbstractLpPrinter.extend({

  initialize() {
    AbstractLpPrinter.prototype.initialize.call(this);
  },

  logTime(part, startTime) {
    const elapsedTime = Date.now() - startTime;
    console.log('[ESCPOS]', (elapsedTime / 1000).toFixed(3), `s -> ${part}`);
  },

  convertHtmlToImageToPNG(htmlText, startTime) {
    const def = new $.Deferred();

    const receiptWidth = 575;
    const $iframe = $('<iframe/>');
    $iframe.css('width', `${receiptWidth}px`);
    // Because in chrome 73 the print preview is visible. we move it off the screen.
    $iframe.css('bottom', '-100000px');

    $('body').append($iframe); // needs to appended to render
    $iframe.ready(() => {
      if (startTime) this.logTime('iframe', startTime);
      const body = $iframe.contents().find('body');
      body.append(htmlText);
      if (startTime) this.logTime('iframe body', startTime);
      DomToImage.toPng(body.get(0), { bgcolor: 'white', style: {'margin': '0'}})
        .then(async (blob) => {
          if (startTime) this.logTime('html as png', startTime);
          def.resolve(blob);
        }, (e) => {
          console.error('[ESCPOS] dom to image failed', e);
          def.reject({
            error: e.message,
          });
        });
    });

    def.always(
      // () => $iframe.remove(),
    );

    return def;
  },

  openDrawerFn() {
    const data = [0x1b, 0x70, 0x30, 0x40, 0x50];

    const builder = new EscPos.EscPosBuilder();
    builder.raw(data);

    return this.printJsonData(builder.serialize());
  },

  convertHtmlToEscposJson(htmlText) {
    const def = new $.Deferred();

    this.convertHtmlToImageToPNG(htmlText, new Date())
      .then((imageBlob) => {
        const builder = new EscPos.EscPosBuilder();
        builder
          .image(imageBlob, {width: EscPos.MAX_IMAGE_WIDTH, alignHT: 'center'})
          .cut();

        def.resolve(builder.serialize());
      }, def.reject);

    return def;
  }

}));
