define([
  'application',
  'jquery',
  'modules/upx/models/user',
  'modules/shop.cash-register-retail/models/pinKey',
],
(App, $, UserModel, PinKeyModel) => UserModel.extend({

  /**
             * Login
             */
  loginUsingPin(pin, options) {
    const key = PinKeyModel.getAuth();
    if (!key) {
      throw new Error('No pin auth set');
    }

    options = options || {};
    const self = this;
    const deferred = new $.Deferred();
    this.setServerFromUpxConfiguration();

    this.upx.setUser(key.user);
    this.upx.setSubaccount(key.subaccount);
    this.upx.setApikey(key.apikey);

    $.when(this.upx.call('CommerceModule', 'loginUsingRelationPin', {
      pin,
    })).then(
      (response) => {
        self.setSubuser(
          response.subaccount,
          response.user,
        );
        self.setHash(response.hash);

        self.save();
        deferred.resolve();
      },
      (error) => {
        deferred.reject(error);
      },
    );

    this.sendEventsOnSuccessfulLogin(deferred, options);

    return deferred.promise();
  },
}));
