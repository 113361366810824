define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/models/Table',

  'upx.modules/ShopModule/models/TableOrder',
  'upx.modules/ShopModule/models/TableOrderPart',

  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'modules/common/components/currency',
  'modules/common/components/locale',
  'modules/common/components/promisify',
], (
  $, _, Backbone, TableModel,
  TableOrderModel, TableOrderPartModel,
  ShopPos, CurrentOrderItem,
  Currency, Locale, Promisify,
) => TableModel.extend({

  async ensureTableOrder() {
    if (this.has('table_order_id')) {
      return this.get('table_order_id');
    }

    // Create table order
    const table_id = this.get('id');
    const name = this.get('name');
    const tableOrderModel = new TableOrderModel({ table_id, name });
    await tableOrderModel.savePromise();

    // Return the created table_order_id
    return this.get('table_order_id');
  },

  ensureTableOrderPart({ table_order_items = null } = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.has('table_order_id')) {
          // Setup model
          table_order_items = this.cleanTableOrderItems(table_order_items || CurrentOrderItem.toJSON());
          const shop_id = ShopPos.getShopId();
          const table_order_id = this.get('table_order_id');
          const newModel = new TableOrderPartModel({
            shop_id,
            table_order_id,
            table_order_items,
          });

          // Create table order part
          const data = await Promisify.deferredToPromise(
            newModel.newWithReturn(),
          );

          // Done
          resolve(data);
        } else {
          // Error
          reject(Locale.translate('table_has_no_order'));
        }
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },

  cleanTableOrderItems(orderItems) {
    const cleanObject = (object, allowedFields) => {
      // the initial data is broken, when discount is used
      // need to fix here to limit the impact of this patchfix
      // proper fix will required rewriting bigger part of checkout
      const is_discounted = object.discount_ppu_wt > 0;
      if (is_discounted) {
        // Save the discount data into extra
        object.extra = _.extend({}, object.extra || {}, {
          discount_data: {
            ppu: object.ppu,
            ppu_wt: object.ppu_wt,
            discount_ppu_wt: object.discount_ppu_wt,
            discount_percentage: object.discount_percentage,
          },
        });

        // Calculate the correct ppu_wt
        object.ppu_wt = Currency.Math.subtract(
          Currency.toCurrency(object.ppu_wt),
          Currency.toCurrency(object.discount_ppu_wt),
        );
      }

      _.each(object, (value, key) => {
        if (allowedFields.indexOf(key) === -1) {
          delete object[key];
        }
      });
    };

    // Clean orderItems
    orderItems.forEach((orderItem) => {
      // Clean subItems
      const subOrderItems = orderItem.subitems = orderItem.sub_items || [];
      subOrderItems.forEach((subOrderItem) => {
        cleanObject(subOrderItem, this.allowedSubItemFields);
      });

      // Clean order item
      cleanObject(orderItem, this.allowedItemFields);
    });

    return orderItems;
  },

  allowedItemFields: [
    'subitems',
    'ppu_wt',
    'before_discount_ppu_wt',
    'quantity',
    'sku',
    'name',
    'description',
    'extra',
    'product_id',
    'shop_product_id',
    'is_turnover',
    'tax_rate_id',
  ],

  allowedSubItemFields: [
    'ppu_wt',
    'before_discount_ppu_wt',
    'quantity',
    'sku',
    'name',
    'description',
    'extra',
    'product_id',
    'shop_product_id',
    'is_turnover',
    'tax_rate_id',
  ],

}));
