define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',
], (
  $, _, Backbone,
  PaymentMethodsSettingModel, LoyaltyProgramModel,
) => Backbone.DeepModel.extend({

  idAttribute: 'id',

  defaults: {
    ppu_wt: '0.00',
    setManually: false,
  },

  modelEvents: {
    'change:price_wt': 'warnPrice',
  },

  setPpuWt(ppu_wt) {
    if (this.get('id') === PaymentMethodsSettingModel.LOYALTY_POINTS_METHOD) {
      this.set('loyaltyPointsSpent', LoyaltyProgramModel.getSpentPointsValueOfFloat(
        parseFloat(ppu_wt),
      ));
    }
    this.set({ ppu_wt });
  },

  warnPrice() {
    console.warn('price_wt should not be set');
  },
}));
