define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/settings/AbstractEscposPrinter',
  'modules/shop.cash-register-retail/models/electronWebViewHandler',

  'modules/common/collections/DeepModelCollection',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/environment',
  'modules/common/components/locale',
  'modules/shop.common/components/mode',
  'modules/common/components/uuid',
  'modules/shop.cash-register-retail/components/printQueue',

  'modules/shop.cash-register-retail/models/ipc/ipc',
  'modules/shop.cash-register-retail/models/deprecatedReactNativeWebViewHandler',
  'modules/shop.cash-register-retail/models/reactNativeConfiguration',
], (
  $, _, Backbone, AbstractEscposPrinter, ElectronWebViewHandler,
  DeepModelCollection,
  CashRegisterApi, Environment, Locale, Mode, Uuid, PrintQueue,
  IPC, DeprecatedReactNativeWebViewHandler, ReactNativeConfiguration,
) => AbstractEscposPrinter.extend({

  initialize() {
    AbstractEscposPrinter.prototype.initialize.call(this);
    this.printQueue = new PrintQueue();
  },

  getWantedType() {
    return this.PRINTER_TYPE_ESCPOS_NETWORK;
  },

  printHtmlFn(htmlText) {
    const printerId = this.get('id');

    const callback = () => this.printHtmlOnPrinterWithoutQueue(htmlText, printerId);

    return this.printQueue.printOnQueue(
      callback,
      printerId,
    );
  },

  printHtmlOnPrinterWithoutQueue(htmlText, printerId) {
    const def = new $.Deferred();

    this.convertHtmlToEscposJson(htmlText)
      .then((jsonData) => {
        this.printJsonDataWithoutQueue(jsonData, printerId)
          .then(def.resolve, def.reject);
      }, def.reject);

    return def;
  },

  printJsonDataForElectron(jsonData, printerId) {
    const def = new $.Deferred();

    ElectronWebViewHandler.printOnReceiptPrinter(printerId, 'network', jsonData)
        .then(def.resolve)
        .catch((err) => def.reject({
          error: err
        }));

    return def;
  },

  printJsonDataForReactNative(jsonData, printerId) {
    // TODO: [DEPRECATED] Should be removed when all React Native apps are updated to use the new IPC
    if (ReactNativeConfiguration.configuration.version == null) { // Old version of the app does not send the version, so it must be using the deprecated handler.
      return this.deprecatedPrintJsonDataForReactNative(jsonData, printerId);
    }

    const def = new $.Deferred();

    IPC.ipcSend('print_escpos', {
      ip: printerId,
      port: 9100,
      serializedData: jsonData,
    }).then(def.resolve).catch((error) => {
      if (error.code) {
        switch (error.code) {
          case 'ERROR_PRINTER_TIMEOUT':
            def.reject({
              error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
              error_title: Locale.translate('printer_with_ip_{ip}_is_not_responding', { ip: printerId }),
              error_message: error.code,
            });
            break;
          default:
            def.reject({
              error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
              error_title: Locale.translate('failed_to_print_on_printer_with_ip_{ip}', { ip: printerId }),
              error_message: error.code,
            });
        }
      } else {
        def.reject({
          error: Locale.translate('network_printers_can_only_be_used_in_app_mode'),
          error_title: Locale.translate('failed_to_print_on_printer_with_ip_{ip}', { ip: printerId }),
        });
      }
    });

    return def;
  },

  /**
   * TODO: [DEPRECATED] Should be removed once all React Native apps are updated to use the new IPC.
   */
  deprecatedPrintJsonDataForReactNative(jsonData, printerId) {
    const def = new $.Deferred();

    // Send the data to the React Native side
    DeprecatedReactNativeWebViewHandler.sendMessageExpectResponse('print', {
      ip: printerId,
      port: 9100,
      serializedData: jsonData,
    }).then((data) => {
      const success = data && data.success;

      if (success) {
        def.resolve();
      } else {
        const errorCode = data && data.errorCode;
        switch (errorCode) {
          case 'ERROR_PRINTER_TIMEOUT':
            def.reject({
              error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
              error_title: Locale.translate('printer_with_ip_{ip}_is_not_responding', { ip: printerId }),
              error_message: errorCode,
            });
            break;
          default:
            def.reject({
              error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
              error_title: Locale.translate('failed_to_print_on_printer_with_ip_{ip}', { ip: printerId }),
              error_message: errorCode,
            });
        }
      }
    }).catch((e) => {
      def.reject({
        error: e,
        error_title: Locale.translate('failed_to_print_on_printer_with_ip_{ip}', { ip: printerId }),
      });
    });

    return def;
  },

  printJsonDataForNodeApi(jsonData, printerId) {
    const def = new $.Deferred();
    const data = {
      serializedData: jsonData,
      type: 'network',
    };

    CashRegisterApi.call(`/escpos-printers/${printerId}/print`, 'post', data, { timeout: 15000, json: true }).then(def.resolve,
      (response) => {
        if (!response.error_code) {
          def.reject({
            error: Locale.translate('failed_to_print_with_message_{0}', [response.error]),
          });
        } else {
          switch (response.error_code) { // all error codes are in node-cashregister-api/server.js
            case 'PRINTER_NOT_IDLE':
              def.reject({
                error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
                error_title: Locale.translate('printer_with_id_{0}_is_not_responding', [printerId]),
                error_message: response.error,
              });
              break;
            default:
              def.reject({
                error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
                error_title: Locale.translate('failed_to_print_on_printer_with_id_{0}', [printerId]),
                error_message: response.error,
              });
          }
        }
      });

    return def;
  },

  printJsonData(jsonData, printerId) {
    printerId = printerId || this.get('id');

    const callback = () => this.printJsonDataWithoutQueue(jsonData, printerId);

    return this.printQueue.printOnQueue(
      callback,
      printerId,
    );
  },

  printJsonDataWithoutQueue(jsonData, printerId) {
    if (Mode.isInAppMode()) {
      return this.printJsonDataForReactNative(jsonData, printerId);
    }
    if (Mode.isInElectronMode()) {
      return this.printJsonDataForElectron(jsonData, printerId);
    }

    return this.printJsonDataForNodeApi(jsonData, printerId);
  },
}));
