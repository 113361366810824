define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',
  'modules/shop.cash-register-retail/models/customer',

  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'upx.modules/RelationsModule/collections/FullInfoRelation',

  'modules/shop.cash-register-retail/models/settings/paymentMethods',
], (
  $, _, Backbone, AppLocalStorage, CustomerModel,
  PaymentMethodItemCollection, CurrentOrderItemCollection, FullInfoRelationCollection,
  PaymentMethodsSettingsModel,
) => {
  const Model = CustomerModel.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/selectedCustomer'),

    modelEvents: {
      change: 'check',
    },

    idAttribute: 'selectId',

    paymentCheck() {
      const modelsToRemove = [];
      PaymentMethodItemCollection.each((model) => {
        if (PaymentMethodsSettingsModel.requiresCustomerByMethod(model.get('id'))) {
          modelsToRemove.push(model);
        }
      });

      PaymentMethodItemCollection.remove(modelsToRemove);
    },

    orderCheck() {
      const modelsToRemove = [];
      CurrentOrderItemCollection.each((model) => {
        if (model.get('type') === CurrentOrderItemCollection.TYPE_MEMBERCARD) {
          modelsToRemove.push(model);
        }
      });

      CurrentOrderItemCollection.remove(modelsToRemove);
    },

    check: _.debounce(function () {
      if (!this.has('id')) {
        this.paymentCheck();
        this.orderCheck();
      }
    }, 10),

    initialize() {
      CustomerModel.prototype.initialize.call(this);
      this.fetch();
      this.startListeners();
      this.check();
    },

    startListeners() {
      this.on('all', this.check, this);
    },

    fetch() {
      const self = this;
      CustomerModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    save() {
      this.set(this.idAttribute, 1);
      CustomerModel.prototype.save.call(this);
    },

    reFetch() {
      return this.load();
    },

    load() {
      const def = $.Deferred();
      this.fetch()
        .done(() => {
          def.resolve();
        });
      return def;
    },

    unload() {
      CustomerModel.prototype.unload.call(this);
      this.destroy();
    },
    updateFromData(data) {
      CustomerModel.prototype.updateFromData.call(this, data);
      this.save();
    },

  });

  return new Model();
});
