define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/settings/AbstractEscposPrinter',
  'modules/shop.cash-register-retail/models/settings/AbstractWebPrinter',
  'modules/shop.cash-register-retail/models/electronWebViewHandler',

  'modules/common/collections/DeepModelCollection',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/environment',
  'modules/shop.cash-register-retail/components/printQueue',
  'modules/common/components/locale',
  'modules/shop.common/components/mode',
], (
  $, _, Backbone, AbstractEscposPrinter, AbstractWebPrinter, ElectronWebViewHandler,
  DeepModelCollection,
  CashRegisterApi, Environment, PrintQueue, Locale, Mode,
) => AbstractEscposPrinter.extend({

  initialize() {
    AbstractEscposPrinter.prototype.initialize.call(this);
    this.printQueue = new PrintQueue();
  },

  getWantedType() {
    return this.PRINTER_TYPE_ESCPOS;
  },

  printHtmlFn(htmlText) {
    const printerId = this.get('id');

    const callback = () => this.printHtmlOnPrinterWithoutQueue(htmlText, printerId);

    return this.printQueue.printOnQueue(
      callback,
      printerId,
    );
  },

  printHtmlOnPrinterWithoutQueue(htmlText, printerId) {
    const def = new $.Deferred();

    this.convertHtmlToEscposJson(htmlText)
      .then((jsonData) => {
        this.printJsonDataWithoutQueue(jsonData, printerId)
          .then(def.resolve, def.reject);
      }, def.reject);

    return def;
  },

  printJsonData(jsonData, printerId) {
    printerId = printerId || this.get('id');

    const callback = () => this.printJsonDataWithoutQueue(jsonData, printerId);

    return this.printQueue.printOnQueue(
      callback,
      printerId,
    );
  },

  printJsonDataWithoutQueue(jsonData, printerId) {
    if (Mode.isInElectronMode()) {
      return this.printJsonDataForElectron(jsonData, printerId);
    }

    return this.printJsonDataForNodeApi(jsonData, printerId);
  },

  printJsonDataForElectron(jsonData, printerId) {
    const def = new $.Deferred();

    ElectronWebViewHandler.printOnReceiptPrinter(printerId, 'usb', jsonData)
      .then(def.resolve)
      .catch((err) => def.reject({
        error: err,
      }));

    return def;
  },

  printJsonDataForNodeApi(jsonData, printerId) {
    const def = new $.Deferred();
    const data = {
      serializedData: jsonData,
    };

    CashRegisterApi.call(`/escpos-printers/${printerId}/print`, 'post', data, { timeout: 15000, json: true }).then(def.resolve,
      (response) => {
        if (!response.error_code) {
          def.reject({
            error: Locale.translate('failed_to_print_with_message_{0}', [response.error]),
          });
        } else {
          switch (response.error_code) { // all error codes are in node-cashregister-api/server.js
            case 'PRINTER_NOT_IDLE':
              def.reject({
                error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
                error_title: Locale.translate('printer_with_id_{0}_is_not_responding', [printerId]),
                error_message: response.error,
              });
              break;
            default:
              def.reject({
                error: Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'),
                error_title: Locale.translate('failed_to_print_on_printer_with_id_{0}', [printerId]),
                error_message: response.error,
              });
          }
        }
      });

    return def;
  },
}));
