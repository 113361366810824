define([
  'jquery',
  'underscore',
  'backbone',
  './receiptPrinterLoader',
  './AbstractLpPrinter',
], (
  $, _, Backbone, ReceiptPrinterLoader, AbstractLpPrinter,
) => {
  const Model = Backbone.Model.extend({

    initialize() {
      this.reload();

      this.PRINTER_TYPE_NONE = this.model.PRINTER_TYPE_NONE;
      this.PRINTER_TYPE_CUPS = this.model.PRINTER_TYPE_CUPS;
      this.PRINTER_TYPE_ESCPOS = this.model.PRINTER_TYPE_ESCPOS;
      this.PRINTER_TYPE_WEB = this.model.PRINTER_TYPE_WEB;
      this.PRINTER_TYPE_ESCPOS_NETWORK = this.model.PRINTER_TYPE_ESCPOS_NETWORK;
      this.PRINTER_TYPE_ZPL = this.model.PRINTER_TYPE_ZPL;
      this.PRINTER_TYPE_LP = this.model.PRINTER_TYPE_LP;
    },

    reload(type) {
      this.model = ReceiptPrinterLoader.loadReceiptPrinterModel(type);

      this.proxyEvents(this.model, this, ['change:id']);
    },

    proxyEvents(from, to, events) {
      events.forEach((e) => {
        to.listenTo(from, e, function () {
          const args = _.toArray(arguments);
          args.unshift(e);
          to.trigger.apply(to, args);
        });
      });
    },

    /**
     * Methods from AbstractPrinter
     */

    resetPrinter() {
      this.model.resetPrinter();
    },

    setPrinter(printerId, type) {
      this.reload(type);
      this.model.setPrinter(printerId, type);
    },

    isWantedType() {
      return this.model.isWantedType();
    },

    canOpenDrawer() {
      return this.model.canOpenDrawer();
    },

    openDrawer() {
      return this.model.openDrawer();
    },

    fetchPrinters(options) {
      return this.model.fetchPrinters(options);
    },

    isPrinterAvailable() {
      const def = $.Deferred();

      const currentPrinterId = this.model.get('id');
      const currentPrinterType = this.model.get('type');

      if (currentPrinterType === this.PRINTER_TYPE_ESCPOS_NETWORK) {
        // Network printers can't be checked if they are available, so we
        // say the printer is always available.
        return def.resolve(true);
      }

      this.model.fetchPrinters().then(({ printers }) => {
        const isAvailable = printers.some((printer) => {
          const printerId = printer.get('id');
          const printerType = printer.get('type');

          return printerId === currentPrinterId && printerType === currentPrinterType;
        });

        def.resolve(isAvailable);
      }, def.reject);

      return def;
    },

    printJsonData(jsonData, printerId) {
      return this.model.printJsonData(jsonData, printerId);
    },

    printHtml(htmlText, options) {
      return this.model.printHtml(htmlText, options);
    },

    selectDevice() {
      return this.model.selectDevice();
    },

    claimDevice(device) {
      return this.model.claimDevice(device);
    },

    get(path) {
      if(this.model !== undefined) {
        return this.model.get(path);
      }

      return null;
    },

    set(path, value) {
      if(this.model !== undefined) {
        this.model.set(path, value);
      }
    },
  });

  return new Model();
});
