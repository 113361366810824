define([
  'jquery',
  'underscore',
  'backbone',
  './printable',
], (
  $, _, Backbone, AbstractPrintable,
) => AbstractPrintable.extend({

  build(receipts) {
    for (const receipt of receipts) {
      this.ccvPinReceipt(receipt);
      this.builder.cut();
    }

    return this.serialize();
  },
}));
