define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/collections/DeepModelCollection',

  'modules/shop.cash-register-retail/models/settings/AbstractEscposUsbPrinter',
  'modules/shop.cash-register-retail/models/settings/AbstractEscposNetworkPrinter',
  'modules/shop.cash-register-retail/models/settings/AbstractWebPrinter',
  'modules/shop.cash-register-retail/models/settings/AbstractPrinter',

  'modules/common/components/appLocalStorage',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.common/components/mode',

], (
  $, _, Backbone, DeepModelCollection,
  EscPosUsbPrinterModel, EscPosNetworkPrinterModel, WebPrinterModel, AbstractPrinter,
  AppLocalStorage, DeviceConfig, ModeComponent,
) => {
  return {
    loadReceiptPrinterModel(type) {
      let ReceiptPrinterModel;

      const params = {
        localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/receiptPrinter'),
        configPath: DeviceConfig.PATH_Printer,
      };

      const AbstractPrinterModel = AbstractPrinter.extend(params);
      const abstractPrinter = new AbstractPrinterModel();

      type = type || abstractPrinter.get('type');

      if (type === abstractPrinter.PRINTER_TYPE_ESCPOS) {
        ReceiptPrinterModel = EscPosUsbPrinterModel.extend(params);
      } else if (type === abstractPrinter.PRINTER_TYPE_ESCPOS_NETWORK) {
        ReceiptPrinterModel = EscPosNetworkPrinterModel.extend(params);
      } else if (type === abstractPrinter.PRINTER_TYPE_WEB) {
        ReceiptPrinterModel = WebPrinterModel.extend(params);
      } else if (ModeComponent.isInAppMode()) {
        ReceiptPrinterModel = EscPosNetworkPrinterModel.extend(params);
      } else if (ModeComponent.isInWebMode()) {
        ReceiptPrinterModel = WebPrinterModel.extend(params);
      } else {
        ReceiptPrinterModel = EscPosUsbPrinterModel.extend(params);
      }

      const printer = new ReceiptPrinterModel();
      // Reset the WebUSB printer on reload of the page. It currently isn't possible to persist the device.
      if (ModeComponent.isInWebMode()) {
        printer.resetPrinter();
      }

      return printer;
    },
  };
});
