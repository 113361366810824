define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/settings/AbstractLpPrinter',
  'modules/common/components/appLocalStorage',
  'modules/shop.common/components/deviceConfig',
], (
  $, _, Backbone, DefaultPrinterModel, AppLocalStorage, DeviceConfig,
) => {
  const Model = DefaultPrinterModel.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/stickerPrinter'),

    configPath: DeviceConfig.PATH_StickerPrinter,

    getWantedType() {
      return this.PRINTER_TYPE_ZPL;
    },
  });

  return new Model();
});
