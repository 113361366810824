define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/models/Order',

  'modules/common/collections/DeepModelCollection',
  'upx.modules/ShopModule/collections/OrderPayment',
  'upx.modules/ShopModule/collections/OrderShipment',
  'upx.modules/ShippingModule/collections/Shipment',
  'modules/shop.cash-register-retail/collections/upx/ShippingType',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'upx.modules/ShopModule/collections/OrderItem',
  'upx.modules/ShippingModule/collections/ShipmentItem',
  'modules/shop.cash-register-retail/components/feature.js',
  'modules/shop.cash-register-retail/components/shipmentMethodType',
  'modules/upx/components/upx',
], (
  $, _, Backbone, OrderModel,
  DeepModelCollection, OrderPaymentCollection, OrderShipmentCollection, ShipmentCollection, ShippingTypeCollection, DefaultShopConfiguration,
  OrderItemCollection, ShipmentItemCollection, Feature, ShipmentMethodType, Upx,
) => {
  const Struct = $.extend(true, {}, OrderModel.prototype.struct);
  Struct.crud.create = 'newOrderWithReturn'; // save function will return object instead of id

  return OrderModel.extend({
    struct: Struct,

    DEFAULT_ORDER_ITEM_UPDATE_OPTIONS: {
      update_silent: false,
      unset_silent: true,
      change_order_items_silent: false,
    },

    DEFAULT_ORDER_ITEM_DATA: {
      // price
      ppu_wt: 0,
      before_discount_ppu_wt: 0,
      tax_rate_id: null,
      quantity: 1,
      // Item
      sku: null,
      name: '',
      description: '',
      shop_product_id: null,
      shipping_method_id: null,
      payment_provider_method_id: null,
      // Settings
      is_recurring: false,
      is_product: false,
      is_shipping: false,
      is_payment: false,
      is_discount: false,
      // product backref
      flat_product: null,
    },

    UPDATE_ORDER_ITEM_FIELDS: [
      'id',
      'sku',
      'quantity',
      'name',
      'description',
      'extra',
      'shop_product_id',
      'attribute_option_ids',
      'shipping_method_id',
      'payment_provider_method_id',
      'is_recurring',
      'is_shipping',
      'is_payment',
      'is_discount',
      'tax_rate_id',
      'subitems',
      'serial_nos',
      'pickup_date',
    ],

    PPU_ORDER_ITEM_FIELDS: [
      'ppu',
      'before_discount_ppu',
    ],

    PPU_WT_ORDER_ITEM_FIELDS: [
      'ppu_wt',
      'before_discount_ppu_wt',
    ],

    paymentItemsKey: 'payment_items',
    shipmentItemsKey: 'shipment_items',
    shipmentItemSerialsKey: 'shipped_serial_nos',
    orderItemsKey: 'order_items',

    checkIfItemsAreLoaded() {
      if (!this.has(this.orderItemsKey)) {
        throw new Error(`${this.orderItemsKey} is not loaded`);
      }
    },

    cleanOrderItems(wantedFields) {
      // Get order items
      const orderItemCollection = this.getOrderItemCollection();
      const newOrderItemCollection = new DeepModelCollection();
      wantedFields = wantedFields || this.getWantedCleanOrderItems();

      // Loop over all current order items
      orderItemCollection.each((model) => {
        const newModelData = {};
        // Loop over all wanted fields
        wantedFields.forEach((field) => {
          // only add the fields that are in the current model
          // This causes only used wanted fields to appear in the new model
          if (model.has(field)) {
            newModelData[field] = model.get(field);
          }
        });
        // Add the data to the new collection
        newOrderItemCollection.add(newModelData);
      });

      // update the order items in the model with the new cleaned order items.
      this.updateSilentOrderItemsFromCollection(newOrderItemCollection);
    },

    cloneOrderItemsWithWantedFields({ wantedOrderItemFields } = {}) {
      // Clone model to keep the original model clean if something goes wrong
      const clone = this.clone();

      clone.set({
        billing_address__merge: false,
        shipping_address__merge: false,
        force_order_if_product_not_active: true,
        force_backorder_if_not_on_stock: true,
        allow_negative_product_qty: true,
      });

      // Clean the order items from unwanted fields;
      clone.cleanOrderItems(wantedOrderItemFields);

      return clone;
    },

    loadOrderItems() {
      return this.fetch();
    },

    loadPaymentItems() {
      const def = new $.Deferred();
      const col = new OrderPaymentCollection();
      const params = {
        start: 0,
        limit: 0,
        sort: [{
          name: 'date_created',
          dir: 'asc',
        }],
        filters: [{
          name: 'order_id__=',
          val: this.get('id'),
        }],
      };

      col.fetch({ params })
        .then((response) => {
          this.unset(this.paymentItemsKey);
          this.set(this.paymentItemsKey, response.data);
          def.resolve();
        }, def.reject);

      return def;
    },

    loadShippedOrderItemSerials() {
      const def = new $.Deferred();
      this.checkIfItemsAreLoaded();
      if (Feature.isProductSerialFeatureEnabled()) {
        const items = this.get(this.orderItemsKey);
        items.forEach((m) => delete m[this.shipmentItemSerialsKey]);

        Upx.call('ShopModule', 'listShippedOrderItemSerials',
          {
            order_id: this.get('id'),
          }).then(
          ({ data }) => {
            if (data) {
              const shipped_serial_nos = {};
              data.forEach(
                ({ id, serial_nos }) => {
                  shipped_serial_nos[id] = serial_nos;
                },
              );
              items.forEach((m) => {
                if (m.id in shipped_serial_nos) {
                  m[this.shipmentItemSerialsKey] = shipped_serial_nos[m.id];
                }
              });
            }
            def.resolve();
          },
          def.reject,
        );
      } else {
        def.resolve(); // feature is not enabled -> nothing to load
      }

      return def;
    },

    loadShipmentItems() {
      const def = new $.Deferred();
      const col = new OrderShipmentCollection();
      const shipmentParams = {
        start: 0,
        limit: 0,
        sort: [{
          name: 'shipment/date_created',
          dir: 'asc',
        }],
        filters: [{
          name: 'order_id__=',
          val: this.get('id'),
        }, {
          name: 'shipment/deleted',
          val: 0,
        }],
      };

      const orderItems = this.getOrderItemCollection();
      col.fetch({ params: shipmentParams })
        .then(() => {
          const shipment_ids = col.pluck('shipment_id');
          if (shipment_ids.length) {
            const itemCollection = new ShipmentItemCollection();
            const itemParams = {
              start: 0,
              limit: 100,
              sort: [{
                name: 'rownb',
                dir: 'asc',
              }],
              filters: [{
                name: 'shipment_id__in_list',
                multi_val: shipment_ids,
              }],
            };
            itemCollection.fetchAll({ params: itemParams }).then(
              () => {
                itemCollection.each((itemModel) => {
                  const backref = itemModel.get('backref');
                  if (backref) {
                    const regex = /ShopModule::OrderItem\(id=(\d+)\)/;
                    const orderItemId = regex.exec(backref)[1];
                    const orderItem = orderItems.get(orderItemId);
                    if (orderItem) {
                      const imageUrl = orderItem.get('flat_product.main_image.url');
                      if (imageUrl) {
                        itemModel.set('main_image_url', imageUrl);
                      }
                    }
                  }

                  const shipmentModel = col.findWhere({
                    shipment_id: itemModel.get('shipment_id'),
                  });
                  const list = shipmentModel.get('shipment.shipment_items') || [];
                  list.push(itemModel.toJSON());
                  shipmentModel.set('shipment.shipment_items', list);
                });
                def.resolve();
              },
              def.reject,
            );
          } else {
            def.resolve();
          }
        }, def.reject);

      def.then(
        () => {
          const items = [];
          col.each((model) => {
            items.push(model.get('shipment'));
          });
          this.unset(this.shipmentItemsKey);
          this.set(this.shipmentItemsKey, items);
        },
      );

      return def;
    },

    getOrderItemCollection() {
      this.checkIfItemsAreLoaded();
      return new OrderItemCollection(this.get(this.orderItemsKey) || []);
    },

    getPaymentItemCollection() {
      if (!this.has(this.paymentItemsKey)) {
        throw new Error(`${this.paymentItemsKey} is not loaded`);
      }
      return new OrderPaymentCollection(this.get(this.paymentItemsKey) || []);
    },

    getShipmentItemCollection() {
      if (!this.has(this.shipmentItemsKey)) {
        throw new Error(`${this.shipmentItemsKey} is not loaded`);
      }
      return new ShipmentCollection(this.get(this.shipmentItemsKey) || []);
    },

    getPreparedModel({ wantedOrderItemFields } = {}) {
      const clone = this.cloneOrderItemsWithWantedFields({ wantedOrderItemFields });

      return new OrderModel(clone.toJSON());
    },

    /**
     * Updates the products silently.
     * This function is handy to use when you want to prevent triggering multiple events on the model
     * @param orderItemCollection
     */
    updateSilentOrderItemsFromCollection(orderItemCollection) {
      this.updateOrderItemsFromArray(orderItemCollection.toJSON(), {
        update_silent: true,
        unset_silent: true,
        change_order_items_silent: true,
      });
    },

    getWantedCleanOrderItems() {
      let wantedFields = this.UPDATE_ORDER_ITEM_FIELDS;
      wantedFields = this.get('calculate_from_wt')
        ? wantedFields.concat(this.PPU_WT_ORDER_ITEM_FIELDS)
        : wantedFields.concat(this.PPU_ORDER_ITEM_FIELDS);
      return wantedFields;
    },

    formatOrderItemData(orderItemData) {
      return _.extend({}, this.DEFAULT_ORDER_ITEM_DATA, orderItemData);
    },

    getShippingOrderItemFromModel(shippingMethodModel) {
      if (!DefaultShopConfiguration.loaded) {
        throw new Error('Shop configuration is not yet loaded');
      }

      const shippingOrderItem = this.formatOrderItemData({
        // price
        ppu: 0,
        ppu_wt: 0,
        before_discount_ppu_wt: 0,
        tax_rate_id: DefaultShopConfiguration.getShipmentTaxRateId(),
        // Item
        name: shippingMethodModel.get('name'),
        shipping_method_id: shippingMethodModel.get('id'),
        default_parcel_provider_id: shippingMethodModel.get('default_parcel_provider_id'),
        is_shipping: true,
      });

      if (shippingMethodModel.has('pickup_date') && shippingMethodModel.get('pickup_date')) {
        shippingOrderItem.pickup_date = shippingMethodModel.get('pickup_date');
      }

      return shippingOrderItem;
    },

    addShippingMethodModel(shippingMethodModel, options) {
      // Get order items
      const order_item_collection = this.getOrderItemCollection();

      const shippingOrderItem = this.getShippingOrderItemFromModel(shippingMethodModel);

      // Add item
      order_item_collection.add(shippingOrderItem);

      // put back the data;
      this.updateOrderItemsFromCollection(order_item_collection, options);

      // Success, return true;
      return true;
    },

    mergeOptions(options, defaults) {
      options = options || {};
      return _.extend({}, defaults, options);
    },

    /**
     * Increases the quantity.
     * @param findWhere
     * @param options
     * @return {boolean} Returns true if found. else false.
     */
    upQuantity(findWhere, options) {
      // Get order items
      const order_item_collection = this.getOrderItemCollection();

      // Check if order_item can be found
      const order_item_model = order_item_collection.findWhere(findWhere);
      if (order_item_model) {
        // Adds one to the quantity
        let quantity = parseInt(order_item_model.get('quantity'));

        if (_.isNaN(quantity)) {
          console.error(`Quantity is NaN ${quantity}`);
          return false;
        }

        order_item_model.set(
          'quantity',
          ++quantity,
        );

        // put back the data;
        this.updateOrderItemsFromCollection(order_item_collection, options);
        return true;
      }

      // Not found, return false.
      return false;
    },

    updateOrderItemsFromArray(order_item_array, options) {
      options = this.mergeOptions(options, this.DEFAULT_ORDER_ITEM_UPDATE_OPTIONS);

      /** unset */
      // Check if it needs to be unset silently
      const unsetOptions = {};
      if (options.unset_silent) {
        unsetOptions.silent = true;
      }

      // Unset order_items
      this.unset('order_items', unsetOptions);

      /** set */
      // Check if it needs to be set silently
      const setOptions = {};
      if (options.update_silent) {
        setOptions.silent = true;
      }

      // Update order_items
      this.set('order_items', order_item_array, setOptions);

      // trigger special order_items event
      if (!options.change_order_items_silent) {
        this.trigger('change_order_items');
      }
    },

    updateOrderItemsFromCollection(order_item_collection, options) {
      this.updateOrderItemsFromArray(order_item_collection.toJSON(), options);
    },

    isPickup() {
      if (!ShippingTypeCollection.loaded) {
        throw new Error('Shipping type collection is not yet loaded');
      }

      const shippingTypeId = this.get('shipping_method.shipping_type_id') || null;
      if (shippingTypeId) {
        const shippingTypeModel = ShippingTypeCollection.get(shippingTypeId);
        if (shippingTypeModel && shippingTypeModel.get('alias') === ShipmentMethodType.TYPE_PICK_AT_STORE) {
          return true;
        }
      }

      return false;
    },
  });
});
