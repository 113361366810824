define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/common/components/appLocalStorage',
  'modules/shop.common/components/deviceConfig',
], (
  $, _, Backbone, Locale, AppLocalStorage, DeviceConfig,
) => {
  const Model = Backbone.DeepModel.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/mediaType'),

    configPath: DeviceConfig.PATH_MediaType,

    TYPE_PRICE_STICKER: 'price',
    TYPE_BREAD_STICKER: 'bread',

    initialize() {
      this.fetch();
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    setByModel(model) {
      this.clear({ silent: true });
      this.set(model.toJSON());
      this.save();
    },

    destroy() {
      Backbone.DeepModel.prototype.destroy.call(this);
      this.set({ id: null });
    },

    getIdOrDefault() {
      return this.get('id') || this.TYPE_NONE;
    },

    getFullCollection() {
      const col = new Backbone.Collection();
      col.add({
        id: this.TYPE_BREAD_STICKER,
        name: Locale.translate('bread_sticker'),
      });
      col.add({
        id: this.TYPE_PRICE_STICKER,
        name: Locale.translate('price_sticker'),
      });
      return col;
    },

  });

  return new Model();
});
