define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/collections/upx/PaymentProvider',
], (
  $, _, Backbone, AppLocalStorage, PaymentMethodsSetting, PaymentProviderCollection,
) => {
  const Model = Backbone.DeepModel.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/settings/terminal'),

    initialize() {
      this.fetch();
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    destroy() {
      Backbone.DeepModel.prototype.destroy.call(this);
      this.clear({ silent: true });
      this.set({ id: null });
    },

    setTerminalByModel(model) {
      this.clear({ silent: true });
      this.set(model.toJSON());
      this.save();
    },

    isCCVPin() {
      return this.get('extra.protocol') === PaymentProviderCollection.TYPE_ALIAS_CCV_PIN_ATTENDED_OPI;
    },

    reload() {
      const model = PaymentMethodsSetting.getTerminalDevicesCollection().get(this.get('id'));
      if (model) {
        this.setTerminalByModel(model);
      } else {
        // Terminal not found, remove it.
        this.destroy();
      }
    },
  });

  return new Model();
});
