define([
  'jquery',
  'underscore',
  'backbone',
  './printable',

  'modules/common/components/locale',
  'modules/common/components/moment',
], (
  $, _, Backbone, AbstractPrintable,
  Locale, Moment,
) => AbstractPrintable.extend({

  build(data) {
    this.companyLogo();

    this.builder
      .text(Locale.translate('cash_drawer_mutation'), { size: 2, bold: true, newLine: true })
      .linefeed()
      .table({
        columns: [
          {
            key: 'key',
            width: 40,
          },
          {
            key: 'value',
            width: 60,
            alignHTRows: 'right',
          },
        ],
        rows: [
          {
            data: {
              key: `${Locale.translate('date')}:`,
              value: new Moment(data.mutation_date).format('DD MMMM YYYY HH:mm'),
            },
          },
          {
            data: {
              key: `${Locale.translate('mutation_type')}:`,
              value: data.mutation_type,
            },
          },
          {
            data: {
              key: `${Locale.translate('amount_in_eur')}:`,
              value: data.mutation_amount,
            },
          },
          {
            data: {
              key: `${Locale.translate('note')}:`,
              value: data.mutation_note || '-',
            },
          },
        ],
      })
      .linefeed();

    this.signature();

    this.builder
      .line()
      .table({
        columns: [
          {
            key: 'key',
            width: 40,
          },
          {
            key: 'value',
            width: 60,
            alignHTRows: 'right',
          },
        ],
        rows: [
          {
            data: {
              key: `${Locale.translate('sales_point')}:`,
              value: data.sales_point,
            },
          },
          {
            data: {
              key: `${Locale.translate('executed_by')}:`,
              value: data.person,
            },
          },
        ],
      })
      .cut();

    return this.serialize();
  },

}));
